import { PapiDeviation, PapiTag } from '@wix/da-papi-types';
import { reduxKey } from './constants';

export enum NetworkStatus {
  Idle = 'idle',
  AwaitingResponse = 'awaiting-response',
  Error = 'error',
}

/**
 * The status of a generation job that has been successfully submitted.
 */
export enum GenerationStatus {
  /**
   * The generation job is still being processed by the backend. We haven't heard anything through
   * Hub yet.
   */
  Processing = 'processing',
  Success = 'success',
  Failure = 'failure',
}

/**
 * These errors occur only after a prompt has been processed and generation has finished. There may be issues with the
 * generated images that necessitate us not displaying them. e.g. a TOS violation.
 */
export enum GenerationError {
  TosViolation = 6,
}

/**
 * A generation job that has been successfully submitted and we're just waiting for the backend to tell
 * us it's finished via Hub.
 */
export interface InProcessGeneration {
  /**
   * This is not the actual uuid of the final deviation(s).
   *
   * This field is a server-side id tied to the generation job.
   */
  uuid: number;

  /**
   * Whether the generation job is still in progress, has finished, or has errored out.
   */
  status: GenerationStatus;

  /**
   * The number of images this job will generate.
   */
  imageCount: number;

  /**
   * If {@link InProcessGeneration.status} indicates a failure, this error number will be populated with a number indicating the
   * cause.
   */
  errorCode?: GenerationError;
}

export interface DreamupStoreSlice {
  /**
   * Prompts that the server returned on initial load or confirmed after a prompt request. Our hub code
   * will be responsible for dispatching the completion actions that update the state of individual prompts.
   */
  generationJobs: {
    [uuid: number]: InProcessGeneration;
  };

  /**
   * To avoid repeat calls to our deviation init endpoint, we'll cache the ones we've already fetched.
   *
   * If this gets any more complicated, we should probably normalize all these entries so there is a single source of
   * truth for deviation data.
   */
  cachedExtendedDeviations: {
    [key: number]: PapiDeviation;
  };

  /**
   * We have a burger menu on mobile that needs to be hidden when we push modals.
   *
   * Mobile only.
   */
  isBurgerMenuOpen: boolean;

  /**
   * The current daily challenge. We show this in hopes the user will want to use Dreamup to submit something for
   * the challenge.
   */
  dailyChallengeTag?: PapiTag;

  /**
   * If this id is present (e.g. provided by query params), we will try to mount the dreamup info modal with the
   * requested deviation.
   */
  pageLoadInfoModalDeviationId?: number;
}

export interface DreamupStoreState {
  [reduxKey]: DreamupStoreSlice;
}
