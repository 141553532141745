import { Middleware, Reducer } from 'redux';
import { all, fork } from 'redux-saga/effects';
import { rootModuleStoreFactory } from '@wix/da-shared-react/pkg/redux/rootModuleStore/factory';
import { reducers as sharedReducers } from '@wix/da-shared-react/pkg/redux/shared/reducers';
import { sagas as sharedSagas } from '@wix/da-shared-react/pkg/redux/shared';
import { AppState } from '../../../types/store';
import logger from '../../helpers/logger';
import { default as headReducers } from '@wix/da-react-app/pkg/client/Head/redux/reducers';

import streamSaga from '../stash/saga';
import stashReducer from '../stash/reducer';

import { dreamupSaga } from './saga';
import { formSaga } from '@wix/da-dreamup/pkg/redux/form/saga';
import formReducer from '@wix/da-dreamup/pkg/redux/form/reducer';
import dreamupReducer from './reducer';

import { dreamupAnonSaga } from '../dreamupAnon/saga';
import dreamupAnonReducer from '../dreamupAnon/reducer';

const buildFakeReducer =
  defaultValue =>
  (state = defaultValue, _action) =>
    state;

// This type makes sure we do not forget to add a reducer for any slice
// of the store nor can we add extras for non-existing slices.
// Needed because monday (thursday) mornings exist.
type AppStateKeys = keyof AppState;
type AppStateReducersMapObject = { [k in AppStateKeys]: Reducer<any> };

const rootReducer: AppStateReducersMapObject = {
  ...headReducers,
  ...sharedReducers,
  rootFolder: buildFakeReducer({}),
  app: buildFakeReducer(''),
  stash: stashReducer,
  ...formReducer,
  ...dreamupAnonReducer,
  ...dreamupReducer,
};

function* rootSaga() {
  yield all(
    [...sharedSagas, streamSaga, formSaga, dreamupSaga, dreamupAnonSaga].map(
      fork
    )
  );
}

export function configureStore(initialState: AppState) {
  const middleware: Middleware[] = [];

  return rootModuleStoreFactory<AppState>(
    middleware,
    logger,
    initialState,
    rootReducer,
    rootSaga,
    false
  );
}
