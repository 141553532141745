import { PapiDeviation } from '@wix/da-papi-types';
import { createSelector } from 'reselect';
import { reduxKey } from './constants';
import { DreamupStoreSlice } from './types';
import { ThemeOptions, ThemeSkin } from '@wix/da-react-context/pkg/constants';
import type { AppState } from '../../../types/store';

function getState(state: any): DreamupStoreSlice {
  if (!state[reduxKey]) {
    return {} as any;
  }
  return state[reduxKey];
}

export function getGenerationJobs(state) {
  return getState(state).generationJobs;
}

export const getDailyChallengeTag = createSelector(
  [getState],
  state => state.dailyChallengeTag
);

export function getCachedDeviation(
  state,
  id: number
): PapiDeviation | undefined {
  return getState(state).cachedExtendedDeviations[id];
}

export function getIsBurgerMenuOpen(state) {
  return getState(state).isBurgerMenuOpen;
}

export function forceDarkThemeSelector() {
  return ThemeOptions.DARK;
}

export function forceDarkThemeSkinSelector() {
  return ThemeSkin.NONE;
}

export function getPageLoadInfoModalDeviationId(state: AppState) {
  return getState(state).pageLoadInfoModalDeviationId;
}
