import React, { useCallback, useContext } from 'react';
import c from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { URLS } from '@wix/da-url';
import DaDreamupLogoAndTextColoredAndBranded from '@wix/da-ds/pkg/DaDreamupLogoAndTextColoredAndBranded';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import { IconSize } from '@wix/da-ds/pkg/Icons/IconWrap';
import EllipsisIcon from '@wix/da-ds/pkg/Icons/Ellipsis';
import IconButtonWithContextMenu from '@wix/da-ds/pkg/Dropdown/Preset/IconButtonWithContextMenuV2';
import DreamupSidebarPlacement from '@wix/da-shared-react/pkg/DealerAd/Placement/DreamupSidebar';
import { getIsBurgerMenuOpen } from '../../../redux/dreamup/selectors';
import { toggleBurgerMenu } from '../../../redux/dreamup/actions';
import SidebarLayout from '../../shared/sidebar/SidebarLayout';
import BurgerButton from '../../shared/BurgerButton';
import SidebarDivider from '../../shared/sidebar/SidebarDivider';
import BackToDaLinkListItem from '../../shared/sidebar/BackToDaLinkListItem';
import ExploreCreationsLinkListItem from '../../shared/sidebar/ExploreCreationsLinkListItem';
import AccountStatus from '../../shared/accountStatus/AccountStatus';
import ContactLinkListItem from '../../shared/sidebar/ContactLinkListItem';
import FaqLinkListItem from './FaqLinkListItem';
import LegalUrlsBlock from './LegalUrlsBlock';
import SellYourArtListItem from './SellYourArtListItem';
import OldDreamupLogoBlock from './OldDreamupLogoBlock';
import s from './Sidebar.scss';

interface Props {}

const Sidebar: React.FC<Props> = () => {
  const isMobile = useContext(MobileContext);
  const isBurgerMenuOpen = useSelector(getIsBurgerMenuOpen);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleBurgerButtonClick = useCallback(() => {
    dispatch(toggleBurgerMenu());
  }, [dispatch]);

  if (!isMobile) {
    return (
      <SidebarLayout
        className={s['root']}
        headerClassName={s['dealer-header']}
        header={
          <>
            <DaDreamupLogoAndTextColoredAndBranded
              view="light"
              className={s['logo']}
            />
            <IconButtonWithContextMenu
              popperManagerProps={{ isInPopup: true }}
              placement="bottom-end"
              items={[
                {
                  items: [
                    {
                      label: t('dreamup.sidebar.header.links.faqs'),
                      link: 'https://www.deviantartsupport.com/en/dreamup',
                      target: '_blank',
                    },
                    {
                      label: t('dreamup.sidebar.header.links.contact'),
                      link: URLS.HELP_NEW_TICKET,
                      target: '_blank',
                    },
                  ],
                },
                {
                  items: [
                    {
                      label: t('dreamup.sidebar.header.links.legal'),
                      complexLabel: (
                        <Trans i18nKey="dreamup.sidebar.header.links.legal">
                          <a
                            className="text-link-level-1-primary"
                            href={URLS.TOS}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Terms of Service
                          </a>
                          ,{' '}
                          <a
                            className="text-link-level-1-primary"
                            href={URLS.PRIVACY}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Privacy Policy
                          </a>
                          ,<br />
                          and{' '}
                          <a
                            className="text-link-level-1-primary"
                            href={URLS.DREAMUP_POLICY}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Dreamup Policy
                          </a>
                        </Trans>
                      ),
                    },
                  ],
                },
              ]}
              icon={EllipsisIcon}
              size={IconSize.MEDIUM}
              variant="main"
              aria-label={t('siteHeader.more')}
            />
          </>
        }
        showChildren
        contentBlocks={[
          <div key="account-status" className={s['plan']}>
            <AccountStatus />
          </div>,
          <SidebarDivider
            key="divider-1"
            className={s['divider']}
            top={24}
            bottom={16}
          />,
          <div key="links">
            <SellYourArtListItem />
            <ExploreCreationsLinkListItem />
            <BackToDaLinkListItem />
          </div>,
          <div key="dealer" className={s['dealer-ad']}>
            <DreamupSidebarPlacement />
          </div>,
          <OldDreamupLogoBlock
            key="old-dreamup"
            className={s['dealer-old-dreamup']}
          />,
        ]}
      />
    );
  }

  return (
    <SidebarLayout
      className={c(s['root'], isBurgerMenuOpen && s['open'])}
      showChildren={!isMobile || isBurgerMenuOpen}
      header={
        <>
          <BurgerButton
            className={s['burger']}
            onClick={handleBurgerButtonClick}
            suppressIndicator
          />
          {!isBurgerMenuOpen && (
            <DaDreamupLogoAndTextColoredAndBranded
              view={'light'}
              className={s['logo']}
            />
          )}
        </>
      }
      contentBlocks={[
        <div key="account-status" className={s['plan']}>
          <AccountStatus />
        </div>,
        <SidebarDivider
          key="divider-1"
          className={s['divider']}
          top={24}
          bottom={16}
        />,
        <div key="links">
          <SellYourArtListItem />
          <ExploreCreationsLinkListItem />
          <FaqLinkListItem />
          <BackToDaLinkListItem />
        </div>,
        <SidebarDivider
          key="divider-2"
          className={s['divider']}
          top={16}
          bottom={24}
        />,
        <ContactLinkListItem key="contact" />,
        <LegalUrlsBlock className={s['legal']} key="legal" />,
      ]}
      footer={<OldDreamupLogoBlock />}
    />
  );
};

Sidebar.displayName = 'Sidebar';

export default Sidebar;
