import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Url } from '@wix/da-url';
import { ReferralType } from '@wix/da-bi/pkg/constants';
import { BiData, type BuyCoreButtonBiEvent } from '@wix/da-bi/pkg/events';
import PlainButtonOrLink from '@wix/da-ds/pkg/Buttons/PlainButtonOrLink';
import BuyCoreBrandedButton from '@wix/da-shared-react/pkg/Button/core/dsPresets/BuyCoreBrandedButton';
import { getHasFreePromptsRemaining } from '@wix/da-dreamup/pkg/redux/form/selectors';
import RootLayout from '../RootLayout';
import AccountStatusHeading from '../AccountStatusHeading';
import AccountStatusDescription from '../AccountStatusDescription';
import s from './NonCoreStatus.scss';

interface Props {}

const NonCoreStatus: React.FC<Props> = () => {
  const { t } = useTranslation();
  const hasFreePromptsRemaining = useSelector(getHasFreePromptsRemaining);
  return (
    <RootLayout
      heading={
        <AccountStatusHeading>
          {hasFreePromptsRemaining
            ? t('dreamup.accountStatus.noCore.heading.hasFreePrompts')
            : t('dreamup.accountStatus.noCore.heading.noFreePrompts')}
        </AccountStatusHeading>
      }
      description={
        <AccountStatusDescription>
          {hasFreePromptsRemaining
            ? t('dreamup.accountStatus.noCore.description.hasFreePrompts')
            : t('dreamup.accountStatus.noCore.description.noFreePrompts')}
        </AccountStatusDescription>
      }
      footer={
        hasFreePromptsRemaining ? (
          <PlainButtonOrLink
            href={Url.coreMembershipLink(Url.dreamupLink())}
            className={s['has-prompts-upgrade-link']}
            target="_blank"
            biData={BiData<BuyCoreButtonBiEvent>({
              evid: 375,
              referral_info: ReferralType.DREAMUP_LEFT,
            })}
          >
            {t('dreamup.accountStatus.noCore.button.label.hasFreePrompts')}
          </PlainButtonOrLink>
        ) : (
          <BuyCoreBrandedButton
            size="large"
            biData={
              {
                referral_info: ReferralType.DREAMUP_LEFT_NO_PROMPTS,
              } as any
            }
            referer={Url.dreamupLink()}
            withSaleSparkles
          >
            {t('dreamup.accountStatus.noCore.button.label.noFreePrompts')}
          </BuyCoreBrandedButton>
        )
      }
    />
  );
};

NonCoreStatus.displayName = 'NonCoreStatus';

export default NonCoreStatus;
