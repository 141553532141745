import React, { useContext, useEffect } from 'react';
import {
  ThemeOptions,
  ThemeSkin,
} from '@wix/da-react-context/pkg/ThemeContext';
import { SessionContext } from '@wix/da-react-context/pkg/publicSession';
import { ReferralType } from '@wix/da-bi/pkg/constants';
import { useAction } from '@wix/da-hooks/pkg/useAction';
import DaApp from '@wix/da-shared-react/pkg/DaApp';
import { RuntimeEnvironment } from '@wix/da-shared-react/pkg/types';
import type { ModalsRenderFunction } from '@wix/da-shared-react/pkg/Modals/DaModal';
import { composeModalRenderers } from '@wix/da-shared-react/pkg/Modals/helpers';
import { modalsRenderFunc as formModalsRenderFunc } from '@wix/da-dreamup/pkg/components/modals/modalsRenderFunc';
import SellerModalLoadable from '@wix/da-adopts-shared/pkg/modals/SellerModalLoadable';
import { AdoptsModalType } from '@wix/da-adopts-shared/pkg/constants';
import { DreamupModals } from '../../../../constants';
import Page from '../Page';
import AnonLandingPage from '../AnonLandingPage';
import UpscaleConfirmationModal from '../UpscaleConfirmationModal';
import InfoModal from '../InfoModal';
import { mountPageLoadModalIfNeeded } from '../../../redux/dreamup/actions';
import s from './DreamupApp.scss';

export interface Props {
  language: string;
  environment?: RuntimeEnvironment;
}

const renderModals: ModalsRenderFunction = composeModalRenderers(
  (modalType, popModal, params) => {
    switch (modalType) {
      case DreamupModals.PROMPT_INFO:
        return (
          <InfoModal onRequestClose={popModal} deviation={params.deviation} />
        );
      case DreamupModals.UPSCALE_CONFIRMATION:
        return (
          <UpscaleConfirmationModal
            onRequestClose={popModal}
            deviation={params.deviation}
          />
        );
      case AdoptsModalType.SELLER_MODAL:
        return (
          <SellerModalLoadable
            branding="dreamup"
            onRequestClose={popModal}
            deviation={params.deviation}
            initialScreen={params.initialScreen}
            isUnpublishedContent
            biReferralInfo={ReferralType.SELL_DEVIATION_DREAMUP}
          />
        );
      default:
        return null;
    }
  },
  formModalsRenderFunc
);

const DreamupPage: React.FC<{}> = () => {
  const { isLoggedIn } = useContext(SessionContext);

  const dispatchMountPageLoadModalIfNeeded = useAction(
    mountPageLoadModalIfNeeded
  );

  useEffect(() => {
    dispatchMountPageLoadModalIfNeeded();
  }, [dispatchMountPageLoadModalIfNeeded]);

  return isLoggedIn ? <Page /> : <AnonLandingPage />;
};
DreamupPage.displayName = 'DreamupPage';

const DreamupApp: React.FC<Props> = ({ language, environment }) => {
  return (
    <DaApp
      language={language}
      environment={environment}
      theme={ThemeOptions.DARK}
      themeSkin={ThemeSkin.NONE}
      modalsRenderFunc={renderModals}
      bodyClassName={s['root']}
      disableHeadTags
    >
      <DreamupPage />
    </DaApp>
  );
};
DreamupApp.displayName = 'DreamupApp';

export default DreamupApp;
