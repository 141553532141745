import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import i18n from 'i18next';
import { isApiError, requestPuppy } from '@wix/da-http-client';
import {
  type PapiDaDeviationStashInit,
  PapiDeviation,
  type PapiDreamsofartUpscale,
  type PapiRequestDreamsofartUpscale,
  PapiUser,
} from '@wix/da-papi-types';
import ddt from '@wix/da-ddt';
import {
  normalizeDeviation,
  normalizeDeviations,
} from '@wix/da-shared-react/pkg/redux/normalizr/helpers';
import { putEntities } from '@wix/da-shared-react/pkg/redux/entities/actions';
import { logPageView } from '@wix/da-shared-react/pkg/biLogger/redux/actions';
import { pushModal } from '@wix/da-shared-react/pkg/Modals/redux/basicActions';
import { updatePointsCount } from '@wix/da-shared-react/pkg/publicSession/actions';
import { getCurrentUser } from '@wix/da-shared-react/pkg/publicSession/selectors';
import { appMounted } from '@wix/da-shared-react/pkg/redux/app/actions';
import { dreamupGenerationSucceeded } from '@wix/da-shared-react/pkg/redux/dreamup/actions';
import { withOffset } from '@wix/da-shared-react/pkg/Stream';
import { putErrorNotification } from '@wix/da-shared-react/pkg/utils/saga';
import { initializeForm } from '@wix/da-dreamup/pkg/redux/form/actions';
import { getFreePromptsRemaining } from '@wix/da-dreamup/pkg/redux/form/selectors';
import { DreamupModals } from '../../../constants';
import { getDeviationStreamId } from '../stash/helpers';
import { getCurrentFolder } from '../stash/selectors';
import {
  extendedDeviationRequestFailed,
  extendedDeviationRequestSucceeded,
  fetchExtendedDeviation,
  generatedDeviationsLoaded,
  upscaleDeviation,
  upscaleInitiationSucceeded,
  upscaleInitiationFailed,
  initializeDreamupApp,
  pushPromptInfoModal,
  mountPageLoadModalIfNeeded,
} from './actions';
import {
  getCachedDeviation,
  getPageLoadInfoModalDeviationId,
} from './selectors';
import { Url } from '@wix/da-url';

function* pageViewHandler() {
  const remainingPrompts = yield select(getFreePromptsRemaining);
  yield put(
    logPageView({
      view: 'dreamup',
      component: 'main',
      num_of_prompts: remainingPrompts,
    })
  );
}

function* handleInitializeDreamupApp(
  action: ReturnType<typeof initializeDreamupApp>
) {
  yield put(initializeForm(action.payload.data, action.payload.options));
}

function* handleUpscaleDeviation(action: ReturnType<typeof upscaleDeviation>) {
  const requestData: PapiRequestDreamsofartUpscale = {
    deviationid: action.payload.deviation.deviationId,
    use_points: action.payload.usePoints,
    creativity_factor: action.payload.creativityLevel,
  };

  const response: PapiDreamsofartUpscale = yield call(
    requestPuppy,
    {
      url: 'upscale',
      method: 'post',
      data: requestData,
    },
    () => ({
      success: true,
      generationId: 5,
      restrictions: {
        pointsBalance: 912,
      },
    }),
    'dreamsofart'
  );

  // TODO: Manually checking for errorCode === 0 until the request code is updated to handle `errorCode === 0` correctly
  if (
    !response ||
    isApiError(response) ||
    (response as any).errorCode === 0 ||
    response.generationId === undefined
  ) {
    yield all([
      putErrorNotification(i18n.t('dreamup.toastError.upscaleDeviationFailed')),
      put(upscaleInitiationFailed()),
    ]);
    ddt.error('dreamup', 'Upscale job initiation failed', response);
  } else {
    yield all([
      put(updatePointsCount(response.restrictions.pointsBalance)),
      put(
        upscaleInitiationSucceeded(
          response.generationId,
          response.imageCount ?? 1,
          response.restrictions
        )
      ),
    ]);
  }
}

function* handleDreamupGenerationSucceeded(
  action: ReturnType<typeof dreamupGenerationSucceeded>
) {
  const currentFolder = yield select(getCurrentFolder);

  const response = yield call(requestPuppy, {
    url: '/deviation/multifetch',
    method: 'get',
    params: {
      deviationids: action.payload.deviationIds,
    },
  });

  if (isApiError(response)) {
    yield putErrorNotification(
      'Sorry, we were unable to load your new deviations. Please refresh the page to try again.'
    );
    return;
  }
  const deviations = response.deviations;
  const deviationStreamId = getDeviationStreamId(currentFolder.folderId);
  const { entities, result } = normalizeDeviations(deviations);
  yield put(putEntities({ entities }));
  yield put(
    withOffset.actions.insertItems({
      streamId: deviationStreamId,
      items: result,
      offset: 0,
    })
  );
  yield put(generatedDeviationsLoaded(action.payload.generationId));
}

function* handlePushPromptInfoModal(action: ReturnType<typeof pushModal>) {
  if (action.payload.type !== DreamupModals.PROMPT_INFO) {
    return;
  }

  const sparseDeviation: PapiDeviation =
    action.payload.options.params.deviation;

  yield put(fetchExtendedDeviation(sparseDeviation));
}

function* sendExtendedDeviationRequest(stashPrivateId: number | undefined) {
  const currentUser = (yield select(getCurrentUser)) as PapiUser;

  const response = yield call(
    requestPuppy,
    {
      url: 'stash/init',
      params: {
        username: currentUser.username,
        deviationid: stashPrivateId,
        type: 'art',
        include_session: false,
      },
    },
    () => {
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line global-require
        const { Fake, deviationsByType } = require('@wix/da-sample-data');
        return {
          deviation: Fake.extendDeviation(deviationsByType.image[0], {
            withDreamup: true,
            withStash: true,
            withMuro: true,
          }),
        };
      }
      return false;
    },
    'dadeviation'
  );

  if (!isApiError(response)) {
    const entities = normalizeDeviation(response.deviation);
    yield put(putEntities({ entities }));
  }

  return response;
}

function* handleFetchExtendedDeviation(
  action: ReturnType<typeof fetchExtendedDeviation>
) {
  const { deviation: potentiallySparseDeviation } = action.payload;
  if (potentiallySparseDeviation.deviationId) {
    const cachedExtendedDeviation = yield select(
      getCachedDeviation,
      potentiallySparseDeviation.deviationId
    );

    if (cachedExtendedDeviation) {
      return;
    }
  }

  const response: PapiDaDeviationStashInit = yield sendExtendedDeviationRequest(
    potentiallySparseDeviation.stashPrivateid
  );

  if (isApiError(response)) {
    yield put(extendedDeviationRequestFailed());
  } else {
    yield put(extendedDeviationRequestSucceeded(response.deviation));
  }
}

export function* handleMountPageLoadModalIfNeeded() {
  const requestedDeviationId = yield select(getPageLoadInfoModalDeviationId);
  if (requestedDeviationId) {
    const response = yield sendExtendedDeviationRequest(requestedDeviationId);
    if (isApiError(response)) {
      yield put(extendedDeviationRequestFailed());
    } else {
      const entities = normalizeDeviation(response.deviation);
      yield put(putEntities({ entities }));
      yield put(pushPromptInfoModal(response.deviation));

      // Since we've loaded what we need given the stashId query param, we'll wipe all query params from the URL
      // to keep it clean
      if (typeof window !== 'undefined') {
        window.history.replaceState({}, '', Url.dreamupLink());
      }
    }
  }
}

export function* dreamupSaga() {
  yield all([
    takeEvery(appMounted, pageViewHandler),
    takeEvery(initializeDreamupApp, handleInitializeDreamupApp),
    takeLatest(upscaleDeviation, handleUpscaleDeviation),
    takeLatest(dreamupGenerationSucceeded, handleDreamupGenerationSucceeded),
    takeLatest(pushModal, handlePushPromptInfoModal),
    takeLatest(fetchExtendedDeviation, handleFetchExtendedDeviation),
    takeLatest(mountPageLoadModalIfNeeded, handleMountPageLoadModalIfNeeded),
  ]);
}
