import omit from 'lodash/omit';
import { handleActions } from 'redux-actions';
import { dreamupGenerationFailed } from '@wix/da-shared-react/pkg/redux/dreamup/actions';
import { generationFromPromptInitiationSucceeded } from '@wix/da-dreamup/pkg/redux/form/actions';
import {
  closeBurgerMenu,
  extendedDeviationRequestSucceeded,
  upscaleInitiationSucceeded,
  initializeDreamupApp,
  toggleBurgerMenu,
  generatedDeviationsLoaded,
} from './actions';
import { reduxKey } from './constants';
import { DreamupStoreSlice, GenerationStatus } from './types';

const reducers = handleActions<DreamupStoreSlice, any>(
  {
    [`${initializeDreamupApp}`]: (
      state,
      { payload: { data, options } }: ReturnType<typeof initializeDreamupApp>
    ) => ({
      ...state,
      generationJobs: data.jobs.reduce(
        (accumulator, currentJob) => ({
          ...accumulator,
          [currentJob.generationId]: {
            uuid: currentJob.generationId,
            imageCount: currentJob.imageCount,
            status: GenerationStatus.Processing,
          },
        }),
        {}
      ),
      dailyChallengeTag: data.dailyChallengeTag,
      pageLoadInfoModalDeviationId: options.deviationToOpen,
    }),
    [`${generationFromPromptInitiationSucceeded}`]: (
      state,
      action: ReturnType<typeof generationFromPromptInitiationSucceeded>
    ) => ({
      ...state,
      generationJobs: {
        ...state.generationJobs,
        [action.payload.generationId]: {
          uuid: action.payload.generationId,
          imageCount: action.payload.imageCount,
          status: GenerationStatus.Processing,
        },
      },
    }),
    [`${upscaleInitiationSucceeded}`]: (
      state,
      action: ReturnType<typeof upscaleInitiationSucceeded>
    ) => ({
      ...state,
      generationJobs: {
        ...state.generationJobs,
        [action.payload.generationId]: {
          uuid: action.payload.generationId,
          imageCount: action.payload.imageCount,
          status: GenerationStatus.Processing,
        },
      },
      restrictions: {
        ...action.payload.restrictions,
      },
    }),
    [`${generatedDeviationsLoaded}`]: (
      state,
      action: ReturnType<typeof generatedDeviationsLoaded>
    ) => ({
      ...state,
      generationJobs: omit(state.generationJobs, action.payload.generationId),
    }),
    [`${dreamupGenerationFailed}`]: (
      state,
      action: ReturnType<typeof dreamupGenerationFailed>
    ) => ({
      ...state,
      generationJobs: {
        ...state.generationJobs,
        [action.payload.generationId]: {
          ...state.generationJobs[action.payload.generationId],
          status: GenerationStatus.Failure,
          errorCode: action.payload.errorCode,
        },
      },
    }),
    [`${toggleBurgerMenu}`]: state => ({
      ...state,
      isBurgerMenuOpen: !state.isBurgerMenuOpen,
    }),
    [`${closeBurgerMenu}`]: state => ({
      ...state,
      isBurgerMenuOpen: false,
    }),
    [`${extendedDeviationRequestSucceeded}`]: (
      state,
      action: ReturnType<typeof extendedDeviationRequestSucceeded>
    ) => ({
      ...state,
      cachedExtendedDeviations: {
        ...state.cachedExtendedDeviations,
        [action.payload.deviation.deviationId]: action.payload.deviation,
      },
    }),
  },
  {
    generationJobs: {},
    cachedExtendedDeviations: {},
    isBurgerMenuOpen: false,
  }
);

export default {
  [reduxKey]: reducers,
};
