import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import type { PapiDeviation } from '@wix/da-papi-types';
import { Url } from '@wix/da-url';
import {
  BiData,
  type DreamupUpscaleConfirmBiEvent,
  type BuyCoreButtonBiEvent,
} from '@wix/da-bi/pkg/events';
import { ReferralType } from '@wix/da-bi/pkg/constants';
import { useAction } from '@wix/da-hooks/pkg/useAction';
import BrandedButton from '@wix/da-ds/pkg/Buttons/BrandedButton';
import { pushPointsModal } from '@wix/da-dreamup/pkg/redux/form/actions';
import { getPricePerPrompt } from '@wix/da-dreamup/pkg/redux/form/selectors';
import { upscaleDeviation } from '../../../../redux/dreamup/actions';
import { jumpToDreamupHeader } from '../../Header';
import { UpscaleConfirmationAction } from '../useUpscaleConfirmationAction';

function getBiForConfirmationAction(
  confirmationAction: UpscaleConfirmationAction,
  deviation: PapiDeviation
) {
  switch (confirmationAction) {
    case UpscaleConfirmationAction.UpgradeToCore:
      return BiData<BuyCoreButtonBiEvent>({
        evid: 375,
        typeid: deviation.typeId,
        itemid: deviation.deviationId,
        referral_info: ReferralType.UPSCALE_CONFIRMATION,
      });
    case UpscaleConfirmationAction.PurchasePoints:
      return undefined;
    case UpscaleConfirmationAction.SubmitWithPrompt:
    case UpscaleConfirmationAction.SubmitWithPoints:
    default:
      return BiData<DreamupUpscaleConfirmBiEvent>({
        evid: 929,
        prompt: deviation.extended?.dreamsofart?.prompt,
        deviationid: deviation.deviationId,
      });
  }
}

interface Props {
  deviation: PapiDeviation;
  confirmationAction: UpscaleConfirmationAction;
  closeCallback:
    | ((
        event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent
      ) => void)
    | undefined;
}

const UpscaleConfirmationButton: React.FC<Props> = ({
  deviation,
  confirmationAction,
  closeCallback,
}) => {
  const { t } = useTranslation();
  const dispatchUpscaleDeviation = useAction(upscaleDeviation);
  const dispatchPushPointsModal = useAction(pushPointsModal);
  const pricePerPrompt = useSelector(getPricePerPrompt);

  const [label, handleClick] = useMemo(() => {
    switch (confirmationAction) {
      case UpscaleConfirmationAction.SubmitWithPoints:
        return [
          t(
            'dreamup.modal.upscaleConfirmation.confirmButton.confirmWithPoints',
            { cost: pricePerPrompt }
          ),
          e => {
            dispatchUpscaleDeviation(deviation, true, undefined);
            closeCallback && closeCallback(e);
          },
        ];
      case UpscaleConfirmationAction.UpgradeToCore:
        return [
          t('dreamup.modal.upscaleConfirmation.confirmButton.upgradeToCore'),
          () =>
            (window.location.href = Url.coreMembershipLink(Url.dreamupLink())),
        ];
      case UpscaleConfirmationAction.PurchasePoints:
        return [
          t('dreamup.modal.upscaleConfirmation.confirmButton.buyPoints', {
            cost: pricePerPrompt,
          }),
          dispatchPushPointsModal,
        ];
      case UpscaleConfirmationAction.SubmitWithPrompt:
      default:
        return [
          t(
            'dreamup.modal.upscaleConfirmation.confirmButton.confirmWithPrompt'
          ),
          e => {
            dispatchUpscaleDeviation(deviation, false, undefined);
            closeCallback && closeCallback(e);
            jumpToDreamupHeader();
          },
        ];
    }
  }, [
    t,
    confirmationAction,
    dispatchUpscaleDeviation,
    dispatchPushPointsModal,
    closeCallback,
    deviation,
    pricePerPrompt,
  ]);

  return (
    <BrandedButton
      size="large"
      onClick={handleClick}
      variant={
        confirmationAction === UpscaleConfirmationAction.UpgradeToCore
          ? 'core-orange'
          : 'brand-green'
      }
      biData={getBiForConfirmationAction(confirmationAction, deviation)}
    >
      {label}
    </BrandedButton>
  );
};

UpscaleConfirmationButton.displayName = 'UpscaleConfirmationButton';

export default UpscaleConfirmationButton;
