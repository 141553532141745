import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { PapiDeviation } from '@wix/da-papi-types';
import {
  BiData,
  DreamupDeleteClickBiEvent,
  type DreamupDownloadBiEvent,
  type DreamupUpscaleModalOpenBiEvent,
  BiContextProvidedValue,
  type EditWithMuroBiEvent,
  CopyPromptClickBiEvent,
  CreateVariationsClickBiEvent,
} from '@wix/da-bi/pkg/events';
import { useAction } from '@wix/da-hooks/pkg/useAction';
import { PremiumTrash } from '@wix/da-ds/pkg/Icons/Confirmations/PremiumTrash';
import ModalCloseContext from '@wix/da-ds/pkg/modals/context';
import { pushConfirmationModal } from '@wix/da-shared-react/pkg/Modals/redux/actionCreators';
import { getDreamupPrompt } from '@wix/da-shared-react/pkg/types/deviation/getters';
import { deleteDeviation } from '@wix/da-shared-react/pkg/redux/deviations/actionCreators';
import { VariationSource } from '@wix/da-dreamup/pkg/redux/form/types';
import {
  changePrompt,
  startVariation,
} from '@wix/da-dreamup/pkg/redux/form/actions';
import { pushUpscaleConfirmationModal } from '../../redux/dreamup/actions';
import { jumpToDreamupHeader } from './Header';

interface DeleteActionOptions {
  /**
   * Because the delete action requires a confirmation modal, we can't rely on the context for disseminating
   * the `sectionname` value. Therefore, we require it to be passed through the hook's options.
   */
  biData: Pick<DreamupDeleteClickBiEvent, 'sectionname' | 'component'>;
  onConfirm?: () => void;
}

export function useDreamupDeleteAction(
  deviation: PapiDeviation,
  { onConfirm, biData }: DeleteActionOptions
) {
  const { t } = useTranslation();
  const closeModal = useContext(ModalCloseContext);
  const dispatch = useDispatch();

  const handleDeleteDeviation = useCallback(() => {
    closeModal();
    dispatch(deleteDeviation({ deviation, shouldRedirect: false }));
    onConfirm && onConfirm();
  }, [dispatch, deviation, closeModal, onConfirm]);

  return useCallback(() => {
    const confirmButtonBiData: DreamupDeleteClickBiEvent = {
      evid: 913,
      deviationid: deviation.deviationId,
      sectionname: biData.sectionname,
    };

    if (biData.component) {
      confirmButtonBiData.component = biData.component;
    }

    dispatch(
      pushConfirmationModal({
        icon: <PremiumTrash />,
        title: t('dreamup.modal.confirmDelete.title'),
        text: t('dreamup.modal.confirmDelete.text'),
        confirmBtnLabel: t('dreamup.modal.confirmDelete.buttons.delete'),
        cancelBtnLabel: t('dreamup.modal.confirmDelete.buttons.cancel'),
        confirmBtnBiData:
          BiData<DreamupDeleteClickBiEvent>(confirmButtonBiData),
        onConfirm: handleDeleteDeviation,
        withCancelButton: true,
      })
    );
  }, [
    dispatch,
    handleDeleteDeviation,
    t,
    biData.sectionname,
    biData.component,
    deviation.deviationId,
  ]);
}

/**
 * Copies the supplied prompt to the prompt input so that the user can modify it as they see fit.
 */
export function useCopyPromptAction(deviation: PapiDeviation) {
  const closeModal = useContext(ModalCloseContext);
  const dispatchChangePrompt = useAction(changePrompt);
  const prompt = getDreamupPrompt(deviation);

  const handleClick = useCallback(() => {
    if (prompt) {
      dispatchChangePrompt(prompt);
      closeModal && closeModal();
      jumpToDreamupHeader();
    }
  }, [dispatchChangePrompt, prompt, closeModal]);

  return {
    onClick: handleClick,
    disabled: !prompt,
    biData: BiData<CopyPromptClickBiEvent>({
      evid: 914,
      deviationid: deviation.deviationId,
      prompt: prompt ?? '',
    }),
  };
}

export function useCreateVariationAction(deviation: PapiDeviation) {
  const closeModal = useContext(ModalCloseContext);
  const dispatchStartVariation = useAction(startVariation);

  const handleClick = useCallback(() => {
    dispatchStartVariation(deviation, {
      variationSource: VariationSource.Dreamup,
    });
    closeModal && closeModal();
    jumpToDreamupHeader();
  }, [dispatchStartVariation, deviation, closeModal]);

  const prompt = getDreamupPrompt(deviation) ?? '';
  return {
    onClick: handleClick,
    disabled: !deviation.extended,
    biData: BiData<CreateVariationsClickBiEvent>({
      evid: 915,
      deviationid: deviation.deviationId,
      prompt,
    }),
  };
}

export function useMuroAction(deviation: PapiDeviation) {
  const { t } = useTranslation();
  let href: string | undefined = undefined;

  if (deviation.extended?.muroOpenUrl) {
    href = deviation.extended.muroOpenUrl;
  } else if (deviation.extended?.muroImportUrl) {
    href = deviation.extended.muroImportUrl;
  }

  return {
    label: t('dreamup.actions.muro.label'),
    href,
    disabled: !href,
    biData: BiData<EditWithMuroBiEvent>({
      evid: 926,
      deviationid: deviation.deviationId,
      sectionname: BiContextProvidedValue,
    }),
  };
}

export function useUpscaleAction(deviation: PapiDeviation) {
  const dispatchPushUpscaleConfirmationModal = useAction(
    pushUpscaleConfirmationModal
  );
  const handleClick = useCallback(() => {
    dispatchPushUpscaleConfirmationModal(deviation);
  }, [deviation, dispatchPushUpscaleConfirmationModal]);

  return {
    onClick: handleClick,
    disabled: deviation.isUpscaled,
    biData: BiData<DreamupUpscaleModalOpenBiEvent>({
      evid: 928,
      prompt: deviation.extended?.dreamsofart?.prompt,
      deviationid: deviation.deviationId,
    }),
  };
}

export function useDownloadAction(deviation: PapiDeviation) {
  const href = deviation.extended?.download?.url;
  return {
    href,
    disabled: !href,
    biData: BiData<DreamupDownloadBiEvent>({
      evid: 927,
      prompt: deviation.extended?.dreamsofart?.prompt,
      deviationid: deviation.deviationId,
    }),
  };
}
