import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import UserSymbol from '@wix/da-shared-react/pkg/User/_partials/UserSymbol/UserSymbol';
import {
  getFreePromptsLimit,
  getHasFreePromptsRemaining,
  getImagesPerPrompt,
} from '@wix/da-dreamup/pkg/redux/form/selectors';
import RootLayout from '../RootLayout';
import AccountStatusHeading from '../AccountStatusHeading';
import AccountStatusDescription from '../AccountStatusDescription';
import PointsBalance from '../PointsBalance';
import ManageCoreLink from '../ManageCoreLink';

interface Props {}

const CorePlusStatus: React.FC<Props> = () => {
  const { t } = useTranslation();
  const freePromptLimit = useSelector(getFreePromptsLimit);
  const imagesPerPrompt = useSelector(getImagesPerPrompt);
  const hasFreePromptsRemaining = useSelector(getHasFreePromptsRemaining);

  return (
    <RootLayout
      heading={
        <AccountStatusHeading>
          {t('dreamup.accountStatus.corePlus.heading')}:{' '}
          <UserSymbol userSymbol="core_plus" />
        </AccountStatusHeading>
      }
      description={
        <AccountStatusDescription>
          {t('dreamup.accountStatus.corePlus.description', {
            postProcess: ['reactPostprocessor', 'featureBrancher'],
            manageLink: <ManageCoreLink />,
            promptCount: freePromptLimit,
            imageCount: imagesPerPrompt,
          })}
        </AccountStatusDescription>
      }
      footer={!hasFreePromptsRemaining && <PointsBalance />}
    />
  );
};

CorePlusStatus.displayName = 'CorePlusStatus';

export default CorePlusStatus;
