import React, { useEffect } from 'react';
import c from 'classnames';
import { useBodyScrollLock } from '@wix/da-ds/pkg/hooks/useBodyScrollLock';
import {
  ThemeOverride,
  ThemeSurface,
} from '@wix/da-react-context/pkg/ThemeContext';
import s from './SidebarLayout.scss';

interface Props {
  header: React.ReactNode;
  contentBlocks: React.ReactNode[];
  showChildren?: boolean;
  className?: string;
  headerClassName?: string;
  footer?: React.ReactNode;
}

const SidebarLayout = React.forwardRef<HTMLDivElement, Props>(
  (
    { header, footer, contentBlocks, showChildren, className, headerClassName },
    ref
  ) => {
    const { onLock, onUnlock, setScrollableRef } = useBodyScrollLock();

    useEffect(() => {
      if (showChildren) {
        onLock();
      } else {
        onUnlock();
      }
    }, [showChildren, onLock, onUnlock]);

    return (
      <aside
        className={c(
          s['root'],
          showChildren && s['showing-content'],
          className
        )}
        ref={ref}
      >
        <div className={s['sticky-block']}>
          <ThemeOverride themeSurface={ThemeSurface.SECONDARY}>
            <div className={c(s['header'], headerClassName)}>{header}</div>
            {showChildren && (
              <div ref={setScrollableRef} className={s['content']}>
                {contentBlocks}
              </div>
            )}
            {footer && showChildren && (
              <div className={s['footer']}>{footer}</div>
            )}
          </ThemeOverride>
        </div>
      </aside>
    );
  }
);

SidebarLayout.displayName = 'SidebarLayout';

export default SidebarLayout;
