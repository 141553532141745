import { PapiDeviation, PapiTag } from '@wix/da-papi-types';
import { pushModal } from '@wix/da-shared-react/pkg/Modals/redux/basicActions';
import { createAction } from 'redux-actions';
import { DreamupModals } from '../../../constants';
import { reduxKey } from './constants';

export const initializeDreamupApp = createAction(
  `${reduxKey}.INITIALIZE_DREAMUP_APP`,
  (
    data: {
      restrictions;
      jobs;
      pointsPackages;
      dailyChallengeTag?: PapiTag;
      defaultFormValues?: {
        promptStrength?: number;
      };
    },
    options: {
      initialPrompt: string;
      expandSettings: boolean;
      persistFromStorage: boolean;
      deviationToOpen?: number;
    }
  ) => ({
    data,
    options,
  })
);

export const upscaleDeviation = createAction(
  `${reduxKey}.UPSCALE_DEVIATION`,
  (
    deviation: PapiDeviation,
    usePoints: boolean,
    creativityLevel: 1 | 2 | 3 | 4 | 5 | undefined
  ) => ({ deviation, usePoints, creativityLevel })
);

export const upscaleInitiationSucceeded = createAction(
  `${reduxKey}.UPSCALE_INITIATION_SUCCEEDED`,
  (generationId: number, imageCount: number, restrictions: any) => ({
    generationId,
    imageCount,
    restrictions,
  })
);

export const upscaleInitiationFailed = createAction(
  `${reduxKey}.UPSCALE_INITIATION_FAILED`,
  () => ({})
);

/**
 * This is fired only after:
 * 1. The deviations for a prompt have been generated
 * 2. We've loaded the necessary data to display the deviation in our thumb grid
 *
 * At this point, we can swap out the placeholder thumbnails for the real one.
 */
export const generatedDeviationsLoaded = createAction(
  `${reduxKey}.GENERATED_DEVIATIONS_LOADED`,
  (generationId: number) => ({ generationId })
);

export const extendedDeviationRequestSucceeded = createAction(
  `${reduxKey}.EXTENDED_DEVIATION_REQUEST_SUCCEEDED`,
  (deviation: PapiDeviation) => ({ deviation })
);

export const extendedDeviationRequestFailed = createAction(
  `${reduxKey}.EXTENDED_DEVIATION_REQUEST_FAILED`,
  () => ({})
);

export const toggleBurgerMenu = createAction(
  `${reduxKey}.TOGGLE_BURGER_MENU`,
  () => ({})
);

export const closeBurgerMenu = createAction(
  `${reduxKey}.CLOSE_BURGER_MENU`,
  () => ({})
);

export const mountPageLoadModalIfNeeded = createAction(
  `${reduxKey}.MOUNT_PAGE_LOAD_MODAL_IF_NEEDED`,
  () => ({})
);

export const pushPromptInfoModal = (deviation: PapiDeviation) =>
  pushModal(DreamupModals.PROMPT_INFO, {
    params: { deviation },
    wrapInModal: false,
  });

export const pushUpscaleConfirmationModal = (deviation: PapiDeviation) =>
  pushModal(DreamupModals.UPSCALE_CONFIRMATION, {
    params: { deviation },
    wrapInModal: false,
  });

export const fetchExtendedDeviation = createAction(
  `${reduxKey}.FETCH_EXTENDED_DEVIATION`,
  (deviation: PapiDeviation) => ({
    deviation,
  })
);
