import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import VisuallyHidden from '@wix/da-a11y/pkg/components/VisuallyHidden';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import PromptCount from '@wix/da-dreamup/pkg/components/PromptCount';
import PromptForm from '@wix/da-dreamup/pkg/components/PromptForm';
import s from './Header.scss';

export function jumpToDreamupHeader() {
  if (window) {
    // I would use `window.location.hash` or `window.location.href` here, but that wasn't jumping to the top
    // for some reason. Maybe the body scroll lock was interfering?
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}

interface Props {}

const Header: React.FC<Props> = () => {
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);

  return (
    <div className={s['root']}>
      {!isMobile && <PromptCount />}

      <VisuallyHidden>
        <h1 className={s['heading']}>{t('dreamup.header.heading')}</h1>
      </VisuallyHidden>

      <PromptForm />
    </div>
  );
};

Header.displayName = 'Header';

export default Header;
