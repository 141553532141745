import React from 'react';
import { useTranslation } from 'react-i18next';
import c from 'classnames';
import type { PapiDeviation } from '@wix/da-papi-types';
import DownloadIcon from '@wix/da-ds/pkg/Icons/24x24/Download';
import EditWithMuroIcon from '@wix/da-ds/pkg/Icons/16x16/Image';
import ReusePromptIcon from '@wix/da-ds/pkg/Icons/16x16/ReusePrompt';
import CreateVariationsIcon from '@wix/da-ds/pkg/Icons/16x16/CreateVariations';
import UpscaleIcon from '@wix/da-ds/pkg/Icons/Expand';
import { useFlag } from '@wix/da-react-context/pkg/flags/hooks';
import { useDeviationAttributes } from '@wix/da-shared-react/pkg/DeviationViews/hooks/useDeviationAttributes';
import {
  useCopyPromptAction,
  useCreateVariationAction,
  useMuroAction,
  useUpscaleAction,
  useDownloadAction,
} from '../../../hooks';
import SubmitButton from '../../_partials/SubmitButton';
import SellButton from '../../_partials/SellButton';
import ActionBarIconButton from '../ActionBarIconButton';
import MoreMenuButton from '../MoreMenuButton';
import ActionBarTextButton from '../ActionBarTextButton';
import s from './ActionsBar.scss';

interface Props {
  deviation: PapiDeviation;
  className?: string;
}

const ActionsBar: React.FC<Props> = ({ deviation, className }) => {
  const { t } = useTranslation();
  const isDreamupSellFlagEnabled = useFlag('dreamup_sell');
  const { isSellable } = useDeviationAttributes(deviation, true);

  const muroAction = useMuroAction(deviation);
  const upscaleAction = useUpscaleAction(deviation);
  const createVariationAction = useCreateVariationAction(deviation);
  const copyPromptAction = useCopyPromptAction(deviation);
  const downloadAction = useDownloadAction(deviation);

  return (
    <div className={c(s['root'], className)}>
      <div className={s['left']}>
        <ActionBarTextButton
          icon={ReusePromptIcon}
          disabled={copyPromptAction.disabled}
          onClick={copyPromptAction.onClick}
          biData={copyPromptAction.biData}
        >
          {t('dreamup.modal.promptInfo.actions.copyPrompt.label')}
        </ActionBarTextButton>
        <ActionBarTextButton
          icon={CreateVariationsIcon}
          disabled={createVariationAction.disabled}
          onClick={createVariationAction.onClick}
          biData={createVariationAction.biData}
        >
          {t('dreamup.modal.promptInfo.actions.createVariations.label')}
        </ActionBarTextButton>

        <ActionBarTextButton
          icon={UpscaleIcon}
          onClick={upscaleAction.onClick}
          disabled={upscaleAction.disabled}
          biData={upscaleAction.biData}
        >
          {t('dreamup.modal.promptInfo.actions.upscale.label')}
        </ActionBarTextButton>

        <ActionBarTextButton
          icon={EditWithMuroIcon}
          href={muroAction.href}
          disabled={muroAction.disabled}
          biData={muroAction.biData}
        >
          {muroAction.label}
        </ActionBarTextButton>
      </div>
      <div className={s['right']}>
        <div className={s['right-icon-buttons']}>
          <ActionBarIconButton
            icon={DownloadIcon}
            tooltipText={t('dreamup.modal.promptInfo.actions.download.label')}
            aria-label={t('dreamup.modal.promptInfo.actions.download.label')}
            href={downloadAction.href}
            disabled={downloadAction.disabled}
            biData={downloadAction.biData}
          />
          <MoreMenuButton deviation={deviation} />
        </div>
        {isSellable && isDreamupSellFlagEnabled && (
          <SellButton deviation={deviation} />
        )}
        <SubmitButton deviation={deviation} />
      </div>
    </div>
  );
};

ActionsBar.displayName = 'ActionsBar';

export default ActionsBar;
