import React from 'react';
import { useTranslation } from 'react-i18next';
import c from 'classnames';
import DaDreamupLogoAndTextColored from '@wix/da-ds/pkg/DaDreamupLogoAndTextColored';
import IngressPromptForm from '@wix/da-dreamup/pkg/components/IngressPromptForm';
import s from './FormSection.scss';
import commonStyles from '../common.scss';

interface Props {}

const FormSection: React.FC<Props> = () => {
  const { t } = useTranslation();

  return (
    <div className={c(s['root'], commonStyles['mobile-section-padding'])}>
      <div className={s['heading-and-description']}>
        <h1 className={s['heading']}>
          {t('dreamupAnon.form.heading', {
            postProcess: ['reactPostprocessor', 'featureBrancher'],
            dreamup: (
              <DaDreamupLogoAndTextColored view="light" className={s['logo']} />
            ),
          })}
        </h1>
        <p className={s['description']}>{t('dreamupAnon.form.description')}</p>
      </div>
      <IngressPromptForm />
      <p className={s['footnote']}>{t('dreamupAnon.form.footnote')}</p>
    </div>
  );
};

FormSection.displayName = 'FormSection';

export default FormSection;
