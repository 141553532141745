import React from 'react';
import { useTranslation } from 'react-i18next';
import type { PapiDeviation } from '@wix/da-papi-types';
import BuyButton from '@wix/da-ds/pkg/Buttons/BuyButton';
import { useSellOrResellAction } from '@wix/da-shared-react/pkg/Sidebar/SidebarBlock/layouts/exclusives/hooks';

interface Props {
  deviation: PapiDeviation;
}

const SellButton: React.FC<Props> = ({ deviation }) => {
  const { t } = useTranslation();
  const [handleSellClick, sellBiData] = useSellOrResellAction(deviation);
  return (
    <BuyButton
      size="medium"
      variant="outline"
      rounded
      onClick={handleSellClick}
      biData={sellBiData}
    >
      {t('dreamup.modal.promptInfo.actions.sell')}
    </BuyButton>
  );
};

SellButton.displayName = 'SellButton';

export default SellButton;
