import { all, takeLatest } from 'redux-saga/effects';
import { initializeAnonLanding } from './actions';
import { put } from '@redux-saga/core/effects';
import { StreamType } from '@wix/da-shared-react/pkg/Stream';
import { StreamInitConfig } from '@wix/da-shared-react/pkg/Stream/withOffset/types';
import { initializeIfNeeded } from '@wix/da-shared-react/pkg/Stream/base/actions';
import { ANON_LANDING_DEVIATION_STREAM_ID } from '../dreamup/constants';

function* handleInitializeAnonLanding(
  action: ReturnType<typeof initializeAnonLanding>
) {
  const streamConfig: StreamInitConfig = {
    streamType: StreamType.WITH_OFFSET,
    autoFetch: false,
    itemsPerFetch: 25,
    items: action.payload.deviations,
  };

  yield put(initializeIfNeeded(ANON_LANDING_DEVIATION_STREAM_ID, streamConfig));
}

export function* dreamupAnonSaga() {
  yield all([takeLatest(initializeAnonLanding, handleInitializeAnonLanding)]);
}
